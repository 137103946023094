
import * as HeroesServices from '@/service/HeroesServices.service.js';

import {mapState} from "vuex";






export default {
    namespaced: true,
    state: () => ({
        heroAliases: [],
        currentHero: null,
    }),
    mutations: {
        ...mapState('OrganisationsModule', ['organisationNames', 'currentOrganisation', 'organisationPassword']),
        updateHeroes(state, heroes) {
            state.heroAliases = heroes
        },
        updateCurrentHero(state, hero) {
            state.currentHero = hero
        },
    },
    actions: {

        async getHeroes({ commit }) {
            const heroes = await HeroesServices.getHeroes()
            commit('updateHeroes', heroes)
        },
        async getHeroById({ commit }, data) {
            console.log("send in store")
            console.log("id", data)
            const hero = await HeroesServices.getHeroById(data.id, data.password)
            commit('updateCurrentHero', hero)
        },
        async updateHero({ commit }, hero) {
            const updatedHero = await HeroesServices.updateHero(hero)
            commit('updateCurrentHero', updatedHero)
        },
        async createHero({ commit }, hero) {
            const createdHero = await HeroesServices.createHero(hero)
            commit('updateCurrentHero', createdHero)
        },
        async saveHeroInStore({ commit }, hero) {
            commit('updateCurrentHero', hero)
        }
    },
}

