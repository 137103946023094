import * as TeamsServices from '@/service/TeamsServices.service.js';



/*
    getTeams,
    createTeam,
    addHeroesInTeam,
    removeHeroesInTeam
 */



export default {
    namespaced: true,
    state: () => ({
        teams: [],
        currentTeam: null,
    }),
    mutations: {
        updateTeams(state, teams) {
            state.teams = teams
        },
        updateCurrentTeam(state, team) {
            state.currentTeam = team
        },
    },
    actions: {
        async getTeams({ commit }) {
            const teams = await TeamsServices.getTeams()
            commit('updateTeams', teams)
        },
        async saveTeamInStore({ commit }, team) {
            commit('updateCurrentTeam', team)
        },
        async updateTeam({ commit }, team) {
            //const updatedTeam = await TeamsServices.updateTeam(team)
            commit('updateCurrentTeam', team)
        },
        async createTeam({ commit }, team) {
            const createdTeam = await TeamsServices.createTeam(team)
            commit('updateCurrentTeam', createdTeam)
        },
        async addHeroesInTeam({ commit }, { idHeroes, team }) {
            console.log("store", idHeroes)
            const updatedTeam = await TeamsServices.addHeroesInTeam(idHeroes, team)
            commit('updateCurrentTeam', updatedTeam)
        },
        async removeHeroesInTeam({ commit }, { idHeroes, team }) {
            const updatedTeam = await TeamsServices.removeHeroesInTeam(idHeroes, team)
            commit('updateCurrentTeam', updatedTeam)
        },
    },
}