import * as OragnisationService from '@/service/OrganisationsServices.service.js';

export default {
    namespaced: true,
    state: () => ({
        organisationNames: [],
        currentOrganisation: null,
        organisationPassword: null,
    }),
    mutations: {
        updateOrganisationNames(state, names) {
            state.organisationNames = names
        },
        updateCurrentOrganisation(state, organisation) {
            console.log("update", organisation)
            state.currentOrganisation = organisation[0]

        },
        updateOrganisationPassword(state, password) {
            console.log("updateOrganisationPassword", password)
            state.organisationPassword = password
        },
    },
    actions: {
        async getOrgs({ commit }) {
            const orgs = await OragnisationService.getOrgs()
            commit('updateOrganisationNames', orgs)
        },
        async getOrgById({ commit, state }, id) {
            const org = await OragnisationService.getOrgById(id, state.organisationPassword)
            commit('updateCurrentOrganisation', org)
            return org
        },
        async updateOrg({ commit }, org) {
            commit('updateCurrentOrganisation', org)
        },
        async createOrg({ commit }, org) {
            const createdOrg = await OragnisationService.createOrg(org)
            commit('updateCurrentOrganisation', createdOrg)
        },
        async addTeamInOrg({ commit }, { team, password }) {
            let data = {
                idTeam: team._id,
            }
            const org = await OragnisationService.addTeamInOrg(data, password)
            console.log("orgfst store", org)
            org.teams[org.teams.length -1] = team
            let data2 = [org]
            commit('updateCurrentOrganisation', data2)
        },
        async removeTeamInOrg({ commit }, { idTeam, password }) {

            let dataTeam = {
                idTeam: idTeam,
            }
            const org = await OragnisationService.removeTeamInOrg(dataTeam, password)
            console.log("orgfst store", org)
            let data2 = [org]
            commit('updateCurrentOrganisation', data2)
        },
        async updatePassword({ commit }, password) {
            console.log("action password", password)
            commit('updateOrganisationPassword', password)
        }


    },
    getters: {
        getOrgById: (state) => (id) => {
            return state.organisationNames.find(org => org.id === id)
        },
        getOrgPassword: () => {
            return this.organisationPassword
        },
    }
}