import Vue from 'vue'
import VueRouter from 'vue-router'
import  store from "@/store"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { levelAuth: 0 },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: { levelAuth: 0 },
  },
  {
    path: '/heros',
    name: 'heroes',
    component: () => import('../views/HeroesView.vue'),
    meta: { levelAuth: 0 },
    children: [
      {
        path: '',
        name: 'heroes-liste',
        component: () => import('../components/ListeHeroes.vue'),
        meta: { levelAuth: 0 },
      },
      {
        path: ':id',
          name: 'heroes-detail',
        component: () => import('../components/ShowHero.vue'),
        meta: { levelAuth: 0 },
        props: true,
      },
    ],
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('../views/TeamsView.vue'),
    meta: { levelAuth: 0 },
    children: [
      {
        path: '',
        name: 'teams-liste',
        component: () => import('../components/ListeEquipes.vue'),
        meta: { levelAuth: 0 },
      },
      {
        path: ':id',
          name: 'team-detail',
        component: () => import('../components/ShowTeam.vue'),
        meta: { levelAuth: 0 },
        props: true,
      },
    ],
  },
  {
    path: '/organisations',
    name: 'organisations',
    component: () => import('../views/OrganisationsView.vue'),
    meta: { levelAuth: 0 },
    children: [
      {
        path: 'secret',
        name: 'secret',
        component: () => import('../components/SecretSentence.vue'),
        meta: { levelAuth: 0 },
      },
      {
        path: '',
        name: 'organisations-liste',
        component: () => import('../components/ListeOrganisations.vue'),
        meta: { levelAuth: 0 },
      },
      {
        path: ':id',
        name: 'organisations-detail',
        component: () => import('../components/ShowOrganisation.vue'),
        meta: { levelAuth: 0 },
        props: true,
      },

    ],
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: { levelAuth: 0 },
  },
  {
    path: '/*',
    name: 'error404',
    component: () => import('../views/AboutView.vue'),
    meta: { levelAuth: 0 },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function checkAccess(to) {
  // routes without a meta levelAuth field are considered to be public
  if ((to.meta.levelAuth) && (to.meta.levelAuth === 1) && (store.state.auth.auth === false)) {
    return false
  }
  return true
}

// check for all routes needing privileges that user is authenticated
router.beforeEach((to, from, next) => {
  console.log(to.path)
  //route non définie : redirection vers la page d'accueil
  if (to.name === 'error404') {
    store.commit('errors/pushError', 'Invalid route')
    next('/')
  } else if (checkAccess(to)) { //restriction d'accès aux routes qui affichent des composants privilégiés
    next()
  } else {
    // redirection vers le composant de saisie de la phrase secrète dès que l'on veut suivre une route privilégiée alors que l'on est pas authentifié
    store.commit('errors/pushError', 'You must be authenticated to access this page')
    next('/organisations/secret')
  }
});



export default router
