// store.js

import Vue from 'vue';
import Vuex from 'vuex';
import HeroesModule from '@/store/HeroesModule';
import TeamsModule from '@/store/TeamsModule';
import OrganisationsModule from "@/store/OrganisationsModule";
import AuthentificationModule from "@/store/authentificationModule";
import errorModule from './error'


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    HeroesModule,
    TeamsModule,
    OrganisationsModule,
    AuthentificationModule,
    error : errorModule
  },
})
/*
export default new Vuex.Store({
  state: {
    organizationPassword: null,
    heroAliases: [],
    currentHero: null,
    teams: [],
    currentTeam: null,
    organizationNames: [],
    currentOrganization: null,
  },
  mutations: {
    setOrganizationPassword(state, password) {
      state.organizationPassword = password;
    },
    setHeroAliases(state, aliases) {
      state.heroAliases = aliases;
    },
    setCurrentHero(state, hero) {
      state.currentHero = hero;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setCurrentTeam(state, team) {
      state.currentTeam = team;
    },
    setOrganizationNames(state, names) {
      state.organizationNames = names;
    },
    setCurrentOrganization(state, organization) {
      state.currentOrganization = organization;
    },
  },
  actions: {
    updateOrganizationPassword({ commit }, password) {
      commit('setOrganizationPassword', password);
    },
    updateHeroAliases({ commit }, aliases) {
      commit('setHeroAliases', aliases);
    },
    updateCurrentHero({ commit }, hero) {
      commit('setCurrentHero', hero);
    },
    updateTeams({ commit }, teams) {
      commit('setTeams', teams);
    },
    updateCurrentTeam({ commit }, team) {
      commit('setCurrentTeam', team);
    },
    updateOrganizationNames({ commit }, names) {
      commit('setOrganizationNames', names);
    },
    updateCurrentOrganization({ commit }, organization) {
      commit('setCurrentOrganization', organization);
    },
  },
});
*/
