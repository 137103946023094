import * as agent from './axios.service.js';



/// /teams/get [ GET ] :
async function getTeamsFromApi(){
    return await agent.getRequest('/teams/get', 'GETALLTEAMS');
}


/// /teams/create [ POST ] :
async function createTeamWithApi(team){
    return await agent.postRequest('/teams/create', team, 'CREATETEAM');
}

/// /teams/addheroes [ PATCH ] :
async function addHeroesInTeamWithApi(idHeroes, team){
    let data = {
        idHeroes: idHeroes,
        idTeam: team._id
    }
    console.log("data", data)
    return await agent.patchRequest('/teams/addheroes', data, 'ADDHEROESTOTEAM');
}


/// /teams/removeheroes [ PATCH ] :
async function removeHeroesInTeamWithApi(idHeroes, team){
    console.log("idHeroes api", idHeroes)
    let data = {
        idHeroes: idHeroes,
        idTeam: team._id
    }
    console.log("datapi", data)
    return await agent.patchRequest('/teams/removeheroes',data, 'REMOVEHEROESFROMTEAM');
}








async function getTeams(){
    let response = null
    try {
        response = await getTeamsFromApi()
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête GET /teams/get', err)
        throw err;
    }
}

async function createTeam(team){
    let response = null
    try {
        response = await createTeamWithApi(team)
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête POST /teams/create', err)
        throw err;
    }
}

async function addHeroesInTeam(idHeroes, team){
    console.log("service", idHeroes)
    let response = null
    try {
        response = await addHeroesInTeamWithApi(idHeroes, team)
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête PATCH /teams/addheroes', err)
        throw err;
    }
}

async function removeHeroesInTeam(idHeroes, team){
    let response = null
    try {
        response = await removeHeroesInTeamWithApi(idHeroes, team)
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête PATCH /teams/removeheroes', err)
        throw err;
    }
}




export {
    getTeams,
    createTeam,
    addHeroesInTeam,
    removeHeroesInTeam



}