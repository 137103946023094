<template>
  <v-app>
    <ErrorDialogue
        title="ERROR"
        width="600"
    >
    </ErrorDialogue>

    <div id="app">
      <router-view>
      </router-view>
    </div>
  </v-app>

</template>

<script>

import ErrorDialogue from "@/components/errorDialogue.vue";

export default {
  name: 'App',
  components: {
    ErrorDialogue,
  },

  data: () => ({
    drawer: false,
  }),


};
</script>
<style scoped>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        margin-top: 150px;
    }
</style>