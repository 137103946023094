import * as agent from '@/service/axios.service.js';




///heroes/getaliases [ GET ]
async function getHeroesFromApi(){
    return await agent.getRequest('/heroes/getaliases', 'GETALLHEROES');
}

/// /heroes/getbyid/:id [ GET ] :
async function getHeroByIdFromApi(id, password){
    console.log("send to api")
    console.log('id', id)
    console.log('password', password)
    return await agent.getRequest(`/heroes/getbyid/${id}?org-secret=` + password, 'GETHEROBYID');
}

/// heroes/update?org-secret=xxx [ PUT ]
async function updateHeroWithApi(hero, password){
    return await agent.putRequest('/heroes/update?org-secret='+ password, hero, 'UPDATEHERO');
}

/// /heroes/create [ POST ]
async function createHeroWithApi(hero){
    return await agent.postRequest('/heroes/create', hero, 'CREATEHERO');
}



async function getHeroes(){
    let response = null
    try {
        response = await getHeroesFromApi()
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête GET /heroes/getaliases', err)
        throw err;
    }
}

async function getHeroById(id, password){
    let response = null
    try {
        console.log("send to service")
        console.log('id', id)
        console.log("password", password)
        response = await getHeroByIdFromApi(id, password)
        return response.data[0];
    } catch (err) {
        console.error('Erreur dans la requête GET /heroes/getbyid/:id', err)
        throw err;
    }
}

async function updateHero(hero, password){
    let response = null
    try {
        response = await updateHeroWithApi(hero, password)
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête PUT /heroes/update?org-secret=xxx', err)
        throw err;
    }
}

async function createHero(hero){
    let response = null
    try {
        response = await createHeroWithApi(hero)
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête POST /heroes/create', err)
        throw err;
    }
}




export {
    getHeroes,
    createHero,
    updateHero,
    getHeroById
}