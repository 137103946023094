import * as agent from './axios.service.js';



/// /orgs/get [ GET ] :
async function getOrgsFromApi(){
    return await agent.getRequest('/orgs/get', 'GETALLORGS');
}

/// /orgs/create [ POST ] :
async function createOrgWithApi(org){
    return await agent.postRequest('/orgs/create', org, 'CREATEORG');
}

/// /orgs/addteam [ PATCH ] :
async function addTeamInOrgWithApi(idTeam, password){
    console.log("api request")
    console.log("idTeam", idTeam)
    console.log("password", password)
    let response = await agent.patchRequest('/orgs/addteam?org-secret='+ password, idTeam  , 'ADDTEAMORG');
    console.log("response 2 service", response)
    return response
}


/// /orgs/removeteam [ PATCH ]:
async function removeTeamInOrgWithApi(idTeam, password){
    return await agent.patchRequest('/orgs/removeteam?org-secret='+ password, idTeam  , 'REMOVETEAMFROMORG');
}

/// /orgs/getbyid/:id [ GET ] :
async function getOrgByIdFromApi(id, password){
    return await agent.getRequest(`/orgs/getbyid/${id}?org-secret=` + password, 'GETORGBYID');
}

async function getOrgs(){
    let response = null
    try {
        response = await getOrgsFromApi()
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête GET /orgs/get', err)
        throw err;
    }
}

async function createOrg(org){
    let response = null
    try {
        response = await createOrgWithApi(org)
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête POST /orgs/create', err)
        throw err;
    }
}

async function addTeamInOrg(idTeam, password){
    let response = null
    console.log("api request")
    console.log("idTeam", idTeam)
    console.log("password", password)
    try {
        response = await addTeamInOrgWithApi(idTeam, password)
        console.log("response fst service", response)
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête PATCH /orgs/addteam', err)
        throw err;
    }
}

async function removeTeamInOrg(idTeam, password){
    let response = null
    try {
        response = await removeTeamInOrgWithApi(idTeam, password)
        console.log("response dele service", response)
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête PATCH /orgs/removeteam', err)
        throw err;
    }
}

async function getOrgById(id, password){
    let response = null
    try {
        response = await getOrgByIdFromApi(id, password)
        return response.data;
    } catch (err) {
        console.error('Erreur dans la requête GET /orgs/getbyid/:id', err)
        throw err;
    }
}

export {
    getOrgs,
    createOrg,
    addTeamInOrg,
    removeTeamInOrg,
    getOrgById
}